import React from "react";
import styled from "styled-components";

const Footer = () => {
  const handleClick = () => {
    window.location.href = "mailto:contact@postlabs.io";
  };

  return (
    <StyledFooter>
      <FooterWrap>
        <h1 className="postlab">포스트랩</h1>
        <p className="address">서울시 강남구 테헤란로 431 저스트코타워 908호</p>
        <p className="email" onClick={handleClick}>
          contact@postlabs.io
        </p>
        <p className="copyright">
          © {new Date().getFullYear()}. All rights reserved by POSTLAB.
        </p>
      </FooterWrap>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;

  height: 298px;
  background-color: black;

  @media (max-width: 791px) {
    padding: 0 20px;
  }
`;

const FooterWrap = styled.div`
  width: 1200px;

  .postlab {
    margin-top: 80px;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .address {
    margin-top: 12px;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
  }

  .email {
    margin-top: 4px;

    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
  }

  .copyright {
    margin-top: 24px;

    font-size: 13px;
    line-height: 18px;
    color: #999999;
  }
`;

export default Footer;
